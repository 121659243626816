
export default {
  name: 'NavDropdownMenu',
  props: {
    items: {
      type: Array,
      required: true,
    },
  },
  computed: {
    dropdownItems() {
      return this.items.slice(1);
    },
  },
};
